<template>
  <hcc-card class="custom-card" color="white">
    <div class="flex-container align-middle align-justify item-card">
      <div class="column-left">
        <p class="card__title">{{ title }}</p>
        <p class="card__description">
          {{ text }}
        </p>
      </div>
      <div class="column-right" v-tooltip="{
        content: $t('configuration.options.error-empty-msg'),
        visible: disabled,
        trigger: 'hover',
        placement: 'left',
      }">
        <hcc-toggle-switch
          class="switch margin-0 large"
          v-model="valueToggle"
          @change="$emit('change', valueToggle)"
          :name="name"
          :disabled="disabled"
        />
      </div>
    </div>
  </hcc-card>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
    },
    text: {
      required: true,
    },
    value: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccToggleSwitch: () => import('@/components/shared/HccToggleSwitch/index.vue'),
  },
  model: {
    event: 'change',
  },
  data() {
    return {
      valueToggle: null,
    };
  },
  watch: {
    value(val) {
      this.valueToggle = val;
    },
  },
  mounted() {
    this.valueToggle = this.value;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_options-tab.scss";
</style>
